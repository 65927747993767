import { useStaticQuery, graphql } from "gatsby"
import { Stripe, PaymentIntent, StripeError } from "@stripe/stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
import { CartProduct, Price } from "../ts/interfaces"
import { Currency } from "../checkout/types/checkoutTypes"

interface PriceProduct extends Price {
  product: {
    id: string
    name: string
  }
}

export interface StripePayload {
  paymentIntent?: PaymentIntent | undefined
  error?: StripeError | undefined
}

/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
let _stripePromise: Promise<Stripe>
let _stripeAffirmUSPromise: Promise<Stripe>
const getStripe = (country = "CA"): Promise<Stripe> => {
  if (country === "CA") {
    if (!_stripePromise) {
      _stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
    }
    return _stripePromise
  }
  if (!_stripeAffirmUSPromise) {
    _stripeAffirmUSPromise = loadStripe(
      process.env.GATSBY_STRIPE_US_PUBLISHABLE_KEY
    )
  }
  return _stripeAffirmUSPromise
}

export const StripeElementsOptions = {
  //hidePostalCode: true,
  /*
  style: {
  base: {
    iconColor: '#666EE8',
    color: '#31325F',
    lineHeight: '40px',
    fontWeight: 300,
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: '15px',

    '::placeholder': {
      color: '#CFD7E0',
    },
    }, 
  } */
}

export default getStripe

let data: {
  allStripePrice: {
    edges: { node: PriceProduct }[]
  }
}

const products: Record<string, CartProduct> = {}

export const LoadProductPrices = () => {
  data = useStaticQuery(graphql`
    query PriceQuery {
      allStripePrice(filter: { active: { eq: true } }) {
        edges {
          node {
            id
            active
            currency
            product {
              id
              name
              metadata {
                shippingRequired
                accountRequired
                avataxCode
                bladeSKU
                trial_days
              }
            }
            unit_amount
            type
            billing_scheme
          }
        }
      }
    }
  `)

  if (data === null || data === undefined) {
    return null
  }

  data.allStripePrice.edges.forEach(edge => {
    const prd = edge.node
    if (prd.active && !products[prd.product.id]) {
      const prices: Record<Currency, Price | null> = { cad: null, usd: null }
      prices[prd.currency] = { ...prd }
      products[prd.product.id] = {
        ...prd.product,
        prices,
      }
    } else if (prd.active && products[prd.product.id]) {
      products[prd.product.id].prices[prd.currency] = { ...prd }
    }
  })
}

export const getCartProduct = (
  productStripeId: string | undefined
): CartProduct | null => {
  if (data === null || (data === undefined && products)) {
    return null
  }

  return products[productStripeId.trim()]
}

export const getStripeId = (
  stripeId: string | undefined,
  stripeTestId: string | undefined
): string | undefined => {
  if (process.env.GATSBY_STRIPE_ENVIRONMENT === "production") {
    return (stripeId || "").trim()
  }
  return (stripeTestId || "").trim()
}

export const cardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#32325d",
      backgroundColor: "#FFFFFF",
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "var(--color-error)",
    },
  },
}
