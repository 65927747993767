const {
  initializeAndTrackGoogleAnalytics,
  initializeAndTrackGoogleTagManager,
  initializeAndTrackFacebookPixel,
  initializeAndTrackTikTokPixel,
  initializeAndTrackHotjar,
  initializeChatwoot,
  initializeLinkedin,
  initializeAndTrackLuckyOrange,
} = require("./services")

const { isEnvironmentValid } = require("./helper")

let isInitialized = false

exports.initializeAndTrack = (location, initialize = false, tagManagerInit) => {
  const options = window.gatsbyPluginGDPRCookiesOptions
  if (
    options &&
    options.waitForInitalization &&
    !initialize &&
    !isInitialized
  ) {
    return
  }

  if (isEnvironmentValid(options.environments)) {
    if (location === undefined || location === null) {
      console.error(
        "Please provide a reach router location to the initializeAndTrack function."
      )
    } else {
      initializeAndTrackGoogleAnalytics(
        options.googleAnalytics,
        location,
        tagManagerInit
      )
      initializeAndTrackGoogleTagManager(options.googleTagManager, location)
      initializeAndTrackFacebookPixel(options.facebookPixel)
      initializeAndTrackTikTokPixel(options.tikTokPixel)
      initializeAndTrackLuckyOrange(options.luckyOrange)
      initializeAndTrackHotjar(options.hotjar)
      initializeChatwoot(options.chatwoot)
      initializeLinkedin(options.linkedin)
    }
  }
}
