import { AddToCartAction, State } from "../../types/checkoutTypes"
import { getUpdatedCartItems } from "../../shared"

export const addToCart = (action: AddToCartAction, state: State): State => {
  return {
    ...state,
    cartItems: getUpdatedCartItems(
      action.payload.id,
      action.payload.quantity,
      state.cartItems
    ),
  }
}
