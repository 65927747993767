/**
 * This file holds all actions related to checkout and creates a
 * reducer to be imported into the CheckoutContext
 */

import { State, Action, CheckoutActionKind } from "../types/checkoutTypes"
import {
  addToCart,
  decrementCartItem,
  incrementCartItem,
  removeFromCart,
} from "./actions"

export const checkoutReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case CheckoutActionKind.GO_TO_STEP: {
      return { ...state, currentStep: action.payload.stepNumber }
    }
    case CheckoutActionKind.SET_SHIPPING: {
      return { ...state, shipping: action.payload.amount }
    }
    case CheckoutActionKind.SET_TAXES: {
      return { ...state, taxes: action.payload.amount }
    }
    case CheckoutActionKind.SET_SHIPPING_NOTES: {
      return { ...state, shippingNotes: action.payload.notes }
    }
    case CheckoutActionKind.ADD_TO_CART: {
      return addToCart(action, state)
    }
    case CheckoutActionKind.REMOVE_FROM_CART: {
      return removeFromCart(action, state)
    }
    case CheckoutActionKind.INCREMENT_CART_ITEM: {
      return incrementCartItem(action, state)
    }
    case CheckoutActionKind.DECREMENT_CART_ITEM: {
      return decrementCartItem(action, state)
    }
    case CheckoutActionKind.CLEAR_CART: {
      return {
        ...state,
        cartItems: [],
        shipping: undefined,
        taxes: undefined,
        couponDiscount: 0,
        couponCode: "",
        couponDescription: "",
      }
    }
    case CheckoutActionKind.CLEAR_COUPONS: {
      return {
        ...state,
        couponDiscount: 0,
        couponDiscountDays: 0,
        couponCode: "",
        couponDescription: "",
      }
    }
    case CheckoutActionKind.SET_COUNTRY: {
      return { ...state, country: action.payload.country }
    }
    case CheckoutActionKind.SET_COUNTRY_INVENTORY: {
      return { ...state, countryInventory: action.payload.country }
    }
    case CheckoutActionKind.SET_CURRENCY: {
      const { currency } = action.payload

      return {
        ...state,
        currency,
      }
    }
    default: {
      return state
    }
    case CheckoutActionKind.SET_COUPON_DISCOUNT: {
      return { ...state, couponDiscount: action.payload.amount }
    }
    case CheckoutActionKind.SET_COUPON_DISCOUNT_DAYS: {
      return { ...state, couponDiscountDays: action.payload.days }
    }
    case CheckoutActionKind.SET_COUPON_CODE: {
      return { ...state, couponCode: action.payload.code }
    }
    case CheckoutActionKind.SET_COUPON_DESCRIPTION: {
      return { ...state, couponDescription: action.payload.description }
    }
  }
}
