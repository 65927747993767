/**
 * This component
 * 1. Manages checkout state with a reducer
 * 2. Provides state and dispatch contexts to components below
 * 3. Takes children as a prop so you can pass JSX to it
 */

import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from "react"

import { checkoutReducer } from "./checkoutReducer"
import { State, Action, CartItem, Currency } from "../types/checkoutTypes"
import { getLs } from "../../utils/localStorage"
import { LoadProductPrices } from "../../utils/stripejs"

const cartItems: CartItem[] = (getLs("cartItems") as CartItem[]) || []
const currency: Currency = (getLs("currency") as Currency) || "usd"
const country: string = (getLs("country_code") as string) || ""
const countryInventory = getLs("country_for_inventory") as string

const checkoutInitialState: State = {
  cartItems,
  currentStep: 0,
  currency,
  shipping: undefined,
  taxes: undefined,
  couponDiscount: 0,
  couponCode: "",
  couponDescription: "",
  accountRequired: false,
  shippingNotes: "",
  country,
  countryInventory,
}

const CheckoutContext = createContext<State>(checkoutInitialState)
const CheckoutDispatchContext = createContext<Dispatch<Action>>(() => {})

export const CheckoutProvider = ({ children }: { children: ReactNode }) => {
  const [checkoutState, checkoutDispatch] = useReducer(
    checkoutReducer,
    checkoutInitialState
  )

  LoadProductPrices()

  return (
    <CheckoutContext.Provider value={checkoutState}>
      <CheckoutDispatchContext.Provider value={checkoutDispatch}>
        {children}
      </CheckoutDispatchContext.Provider>
    </CheckoutContext.Provider>
  )
}

export function useCheckoutState() {
  return useContext(CheckoutContext)
}

export function useCheckoutDispatch() {
  return useContext(CheckoutDispatchContext)
}
